import Http from "@/plugins/http";
import authStore from "@/modules/auth/store/index.js";
export default {
  async allMediaCollection(context) {
    try {
      const response = await Http.get("media-collections/my-collection", {
        params: {
          account_id: authStore.state.user.account_id,
        },
      });
      context.commit("setMediaCollections", response.data.mediaCollections);
    } catch (error) {
      return false;
    }
  },

  async createOrUpdateMediaCollection(context, mediaCollection) {
    try {
      if (mediaCollection._id) {
        await Http.put(`media-collections/${mediaCollection._id}`, mediaCollection);
        await context.commit("updateMediaCollection", mediaCollection);

        return true;
      }
      const response = await Http.post("media-collections", mediaCollection);
      await context.commit("addMediaCollection", response.data.mediaCollection);

      return true;
    } catch (error) {
      return false;
    }
  },

  async removeMediaCollection(context, mediaCollection) {
    try {
      await Http.delete(`media-collections/${mediaCollection._id}`);
      context.commit("removeMediaCollection", mediaCollection);
    } catch (error) {
      throw error;
    }
  },
  setMediasLikedByUser(context, userLikes) {
    context.commit("setMediasLikedByUser", userLikes);
  },
  incrementMediaLiked(context, userLike) {
    context.commit("incrementMediaLiked", userLike);
  },
  removeLikedMedia(context, userLike) {
    context.commit("removeLikedMedia", userLike);
  },
};
